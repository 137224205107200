import { Decimal } from 'decimal.js';

type CurrencyFormatOptions = Exclude<
  Intl.NumberFormatOptions,
  'currency-display'
> & {
  currencyDisplay: 'symbol' | 'code' | 'name';
};

interface CurrencyFormat {
  locale: string;
  options: CurrencyFormatOptions;
}

export const Currency: { AUD: CurrencyFormat } = {
  AUD: {
    locale: 'en-AU',
    options: {
      style: 'currency',
      currency: 'AUD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};

export interface NumberFormatOptions {
  precision?: number;
  prefixSymbol?: string;
  suffixSymbol?: string;
  withSign?: boolean;
}

export const formatNumber = (
  value?: string | number | null,
  options?: NumberFormatOptions,
): string => {
  const { precision, prefixSymbol, suffixSymbol, withSign } = {
    precision: 0,
    prefixSymbol: '',
    suffixSymbol: '',
    withSign: false,
    ...options,
  };

  const ourValue = value !== null && value !== undefined && String(value);
  const number = ourValue && !isNaN(Number(ourValue)) && Number(ourValue);

  if (!ourValue || (!number && number !== 0)) {
    return '—';
  }

  const sign = `${withSign && number > 0 ? '+' : ''}`;
  const formatted = number.toFixed(precision);

  return `${sign}${prefixSymbol}${formatted}${suffixSymbol}`;
};

export const formatPercentage = (
  value?: string | number | null,
  precision = 0,
  withSign = false,
): string => {
  return formatNumber(value, {
    precision,
    suffixSymbol: '%',
    withSign,
  });
};

export function formatCurrency(
  value?: string | number | null,
  currency: CurrencyFormat = Currency.AUD,
): string {
  if (isNaN(Number(value)) || value === '' || value === null) {
    return '—';
  }

  const amount = Number(value);
  return (
    new Intl.NumberFormat(currency.locale, currency.options)
      .format(amount)
      // workaround for samsung devices
      // see: https://github.com/spaceship-fspl/ui.spaceship/pull/3793
      .replace(/[A-Za-z]/g, '')
  );
}

export function formatCurrencyWithNoDecimal(
  value?: string | number | null,
): string {
  return formatCurrency(value, {
    locale: Currency.AUD.locale,
    options: {
      ...Currency.AUD.options,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  });
}

export function formatCurrencyWithCodeAndSymbol(
  value?: string | number | null,
): string {
  return formatCurrency(value).replace(/\$/g, 'A$');
}

export function formatUSCurrencyWithCodeAndSymbol(
  value?: string | number | null,
): string {
  return formatCurrency(value).replace(/\$/g, 'US$');
}

export function humanReadable(
  value: number,
  digits = 0,
  rounding: Decimal.Rounding = Decimal.ROUND_DOWN,
): string {
  if (value < 1000) {
    return `$${value.toString()}`;
  }
  const units = ['k', 'm', 'bn', 't'];
  const order = Math.floor(Math.log(value) / Math.log(1000));
  const unitName = units[order - 1];
  const dValue = new Decimal(value);
  const num = dValue.dividedBy(1000 ** order).toDP(digits, rounding);
  return `$${num}${unitName}`;
}

export const enumToString = (
  enumType: { [value: number]: string },
  enumValue?: number | null,
): string => {
  const stringValue = enumValue && enumType[enumValue];

  if (stringValue) {
    return stringValue;
  } else {
    throw new Error('Invalid enum value');
  }
};

export const capitalizeWords = (sentence?: string): string => {
  if (!sentence) {
    return '';
  }

  return sentence.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const capitalizeFirstLetter = (sentence?: string): string => {
  if (!sentence) {
    return '';
  }

  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const truncateDecimal = (n: number, digits = 4): number =>
  new Decimal(n).toDP(digits, Decimal.ROUND_DOWN).toNumber();

export const camelCaseToText = (str: string): string => {
  return str
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    })
    .trim();
};

export const sanitizeBankAccountName = (name: string): string =>
  name.replace(/[^a-zA-Z0-9 ]/g, '');

export const maskBankAccountNumber = (accountNumber?: string): string =>
  accountNumber && accountNumber.length > 3
    ? `**** ${accountNumber.substr(accountNumber.length - 4)}`
    : '';

export const formatPhone = (
  phone?: string | null,
  groupingSize = 3,
): string => {
  if (phone === undefined || phone === null) {
    return '';
  }
  phone = phone.replace(/ /g, '');
  const groups = [];
  for (let i = 0; i < phone.length; i += groupingSize) {
    groups.push(phone.substr(i, groupingSize));
  }
  return groups.join(' ').trim();
};

export const stripWhitespace = (value: string): string =>
  value.replace(/\s/g, '');

export const formatYears = (count: number): string =>
  `${count} year${count !== 1 ? 's' : ''}`;

export const normalizeAccents = (value: string): string => {
  const withDia =
    'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçĐđÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const withoutDia =
    'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDdIIIIiiiiUUUUuuuuNnSsYyyZz';
  let normalizedValue = value
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z][\s])/g, '');

  // Some Vietnamese characters
  [...withDia].forEach((dia, index) => {
    const re = new RegExp(dia ?? '', 'g');
    normalizedValue = normalizedValue.replace(re, withoutDia[index] ?? '');
  });

  return normalizedValue;
};
