
    /**
    * THIS FILE IS GENERATED. DO NOT EDIT!
    * @see generate-icons.js
    */
    import { FeatherActivity } from './components/feather-activity';
import { FeatherAlertOctagon } from './components/feather-alert-octagon';
import { FeatherAlertTriangle } from './components/feather-alert-triangle';
import { FeatherArrowDown } from './components/feather-arrow-down';
import { FeatherArrowLeft } from './components/feather-arrow-left';
import { FeatherArrowRight } from './components/feather-arrow-right';
import { FeatherArrowUp } from './components/feather-arrow-up';
import { FeatherArrowUpRight } from './components/feather-arrow-up-right';
import { FeatherAward } from './components/feather-award';
import { FeatherCalendar } from './components/feather-calendar';
import { FeatherCamera } from './components/feather-camera';
import { FeatherCheck } from './components/feather-check';
import { FeatherCheckCircle } from './components/feather-check-circle';
import { FeatherChevronDown } from './components/feather-chevron-down';
import { FeatherChevronLeft } from './components/feather-chevron-left';
import { FeatherChevronRight } from './components/feather-chevron-right';
import { FeatherChevronUp } from './components/feather-chevron-up';
import { FeatherCircle } from './components/feather-circle';
import { FeatherClipboard } from './components/feather-clipboard';
import { FeatherClock } from './components/feather-clock';
import { FeatherCopy } from './components/feather-copy';
import { FeatherDollarSign } from './components/feather-dollar-sign';
import { FeatherDownload } from './components/feather-download';
import { FeatherEdit2 } from './components/feather-edit-2';
import { FeatherEye } from './components/feather-eye';
import { FeatherEyeOff } from './components/feather-eye-off';
import { FeatherFileText } from './components/feather-file-text';
import { FeatherFilter } from './components/feather-filter';
import { FeatherGift } from './components/feather-gift';
import { FeatherHelpCircle } from './components/feather-help-circle';
import { FeatherHome } from './components/feather-home';
import { FeatherInfo } from './components/feather-info';
import { FeatherLayers } from './components/feather-layers';
import { FeatherList } from './components/feather-list';
import { FeatherLock } from './components/feather-lock';
import { FeatherMail } from './components/feather-mail';
import { FeatherMenu } from './components/feather-menu';
import { FeatherMessageSquare } from './components/feather-message-square';
import { FeatherMinus } from './components/feather-minus';
import { FeatherMoon } from './components/feather-moon';
import { FeatherMoreVertical } from './components/feather-more-vertical';
import { FeatherPlus } from './components/feather-plus';
import { FeatherPlusCircle } from './components/feather-plus-circle';
import { FeatherRefreshCw } from './components/feather-refresh-cw';
import { FeatherSave } from './components/feather-save';
import { FeatherSearch } from './components/feather-search';
import { FeatherSettings } from './components/feather-settings';
import { FeatherShare } from './components/feather-share';
import { FeatherSlash } from './components/feather-slash';
import { FeatherSun } from './components/feather-sun';
import { FeatherFrown } from './components/feather-frown';
import { FeatherTrash2 } from './components/feather-trash-2';
import { FeatherUpload } from './components/feather-upload';
import { FeatherUser } from './components/feather-user';
import { FeatherX } from './components/feather-x';
import { FeatherZap } from './components/feather-zap';
import { FeatherZapOff } from './components/feather-zap-off';
import { StreamlineAccountingCoinsStack } from './components/streamline-accounting-coins-stack';
import { StreamlineAccountingCoins } from './components/streamline-accounting-coins';
import { StreamlineAlertTriangle } from './components/streamline-alert-triangle';
import { StreamlineAmericanFootballBall1 } from './components/streamline-american-football-ball-1';
import { StreamlineAnalyticsPie1 } from './components/streamline-analytics-pie-1';
import { StreamlineAstronomyTelescopeStars } from './components/streamline-astronomy-telescope-stars';
import { StreamlineBuildingModern2 } from './components/streamline-building-modern-2';
import { StreamlineBusinessContractApprove } from './components/streamline-business-contract-approve';
import { StreamlineButtonLoopArrow } from './components/streamline-button-loop-arrow';
import { StreamlineCashBriefcase } from './components/streamline-cash-briefcase';
import { StreamlineCashBulb } from './components/streamline-cash-bulb';
import { StreamlineCashPaymentBills } from './components/streamline-cash-payment-bills';
import { StreamlineCashPaymentWallet } from './components/streamline-cash-payment-wallet';
import { StreamlineCashSearch } from './components/streamline-cash-search';
import { StreamlineCheck1 } from './components/streamline-check-1';
import { StreamlineCheckCircle1 } from './components/streamline-check-circle-1';
import { StreamlineCoffeeToGo } from './components/streamline-coffee-to-go';
import { StreamlineColorPalette } from './components/streamline-color-palette';
import { StreamlineCommonFileRotate } from './components/streamline-common-file-rotate';
import { StreamlineContentPaperEdit } from './components/streamline-content-paper-edit';
import { StreamlineContentPencilWrite } from './components/streamline-content-pencil-write';
import { StreamlineConversationChatBubble } from './components/streamline-conversation-chat-bubble';
import { StreamlineCursorChoose } from './components/streamline-cursor-choose';
import { StreamlineDiagramArrowDashRight } from './components/streamline-diagram-arrow-dash-right';
import { StreamlineDiscountCircle } from './components/streamline-discount-circle';
import { StreamlineDownloadDashArrow } from './components/streamline-download-dash-arrow';
import { StreamlineEarthCash } from './components/streamline-earth-cash';
import { StreamlineFaceId4 } from './components/streamline-face-id-4';
import { StreamlineFastFoodBurgerDrink } from './components/streamline-fast-food-burger-drink';
import { StreamlineFlagPlain3 } from './components/streamline-flag-plain-3';
import { StreamlineGenderHetero } from './components/streamline-gender-hetero';
import { StreamlineGraphStatsAscend } from './components/streamline-graph-stats-ascend';
import { StreamlineHourglass } from './components/streamline-hourglass';
import { StreamlineKoala } from './components/streamline-koala';
import { StreamlineLegalHammer } from './components/streamline-legal-hammer';
import { StreamlineLegalScale1 } from './components/streamline-legal-scale-1';
import { StreamlineLegalScaleUnequal1 } from './components/streamline-legal-scale-unequal-1';
import { StreamlineLightBulb } from './components/streamline-light-bulb';
import { StreamlineLightModeSunny } from './components/streamline-light-mode-sunny';
import { StreamlineLogout1 } from './components/streamline-logout-1';
import { StreamlineMakeUpEye } from './components/streamline-make-up-eye';
import { StreamlineMonitorHeartBeat } from './components/streamline-monitor-heart-beat';
import { StreamlineMultipleNeutral2 } from './components/streamline-multiple-neutral-2';
import { StreamlineMultipleUsers1 } from './components/streamline-multiple-users-1';
import { StreamlineNaturalDisasterFire } from './components/streamline-natural-disaster-fire';
import { StreamlineNetworkPin } from './components/streamline-network-pin';
import { StreamlineNetworkUser } from './components/streamline-network-user';
import { StreamlineOfficialBuilding2 } from './components/streamline-official-building-2';
import { StreamlinePastaBowlWarm } from './components/streamline-pasta-bowl-warm';
import { StreamlineReligionPeace1 } from './components/streamline-religion-peace-1';
import { StreamlineSatellite1 } from './components/streamline-satellite-1';
import { StreamlineSavingBank1 } from './components/streamline-saving-bank-1';
import { StreamlineSearch1 } from './components/streamline-search-1';
import { StreamlineShieldCheck } from './components/streamline-shield-check';
import { StreamlineShieldWarning } from './components/streamline-shield-warning';
import { StreamlineSingleManBook } from './components/streamline-single-man-book';
import { StreamlineSingleNeutralShield } from './components/streamline-single-neutral-shield';
import { StreamlineSingleNeutral } from './components/streamline-single-neutral';
import { StreamlineSmileySmile2 } from './components/streamline-smiley-smile-2';
import { StreamlineSpaceAstronautAlternate } from './components/streamline-space-astronaut-alternate';
import { StreamlineSpaceRocketFlying } from './components/streamline-space-rocket-flying';
import { StreamlineStudyDesk } from './components/streamline-study-desk';
import { StreamlineTargetCenter1 } from './components/streamline-target-center-1';
import { StreamlineTaskListMultiple } from './components/streamline-task-list-multiple';
import { StreamlineTaxiWaveBusinessMan } from './components/streamline-taxi-wave-business-man';
import { StreamlineTimeClockCircle } from './components/streamline-time-clock-circle';
import { StreamlineTouchId2 } from './components/streamline-touch-id-2';
import { StreamlineToysLego } from './components/streamline-toys-lego';
import { StreamlineTree } from './components/streamline-tree';
import { StreamlineWalking } from './components/streamline-walking';
import { StreamlineWaterProtectionFish } from './components/streamline-water-protection-fish';
import { StreamlineWaterStraw } from './components/streamline-water-straw';
import { StreamlineWeatherCloudRain } from './components/streamline-weather-cloud-rain';
import { AnalyticsLineGraph } from './components/analytics-line-graph';
import { AstronomyPlanet } from './components/astronomy-planet';
import { Award } from './components/award';
import { Boosts } from './components/boosts';
import { Briefcase } from './components/briefcase';
import { CloudEmpty } from './components/cloud-empty';
import { DatabaseSearch } from './components/database-search';
import { DollarBills } from './components/dollar-bills';
import { DollarCircle } from './components/dollar-circle';
import { DollarCirleLines } from './components/dollar-cirle-lines';
import { FundFromSpaceship } from './components/fund-from-spaceship';
import { FundToSpaceship } from './components/fund-to-spaceship';
import { Galaxy } from './components/galaxy';
import { GaugeDashboardMax } from './components/gauge-dashboard-max';
import { HelpWheel } from './components/help-wheel';
import { KeyboardArrowLeft } from './components/keyboard-arrow-left';
import { MergeArrow } from './components/merge-arrow';
import { MoneyPlant } from './components/money-plant';
import { SmileyBlessed } from './components/smiley-blessed';
import { TipLightbulb } from './components/tip-lightbulb';
import { Checklist } from './components/checklist';
import { CircleCheck } from './components/circle-check';
import { Circle } from './components/circle';
import { EtfAssetFallback } from './components/etf-asset-fallback';
import { EtfProductStars } from './components/etf-product-stars';
import { EtfProduct } from './components/etf-product';
import { Facebook } from './components/facebook';
import { IndicatorTriangle } from './components/indicator-triangle';
import { Instagram } from './components/instagram';
import { Linkedin } from './components/linkedin';
import { PadlockAttention } from './components/padlock-attention';
import { Payid } from './components/payid';
import { Rss } from './components/rss';
import { Spaceship } from './components/spaceship';
import { StocksProductStars } from './components/stocks-product-stars';
import { StocksProduct } from './components/stocks-product';
import { SuperGlobalIndexStars } from './components/super-global-index-stars';
import { SuperGlobalIndex } from './components/super-global-index';
import { SuperGrowthxStars } from './components/super-growthx-stars';
import { SuperGrowthx } from './components/super-growthx';
import { SuperProductStars } from './components/super-product-stars';
import { SuperProduct } from './components/super-product';
import { TaxTime } from './components/tax-time';
import { Timeline } from './components/timeline';
import { Twitter } from './components/twitter';
import { VoyagerEarthStars } from './components/voyager-earth-stars';
import { VoyagerEarth } from './components/voyager-earth';
import { VoyagerExplorerStars } from './components/voyager-explorer-stars';
import { VoyagerExplorer } from './components/voyager-explorer';
import { VoyagerGalaxyStars } from './components/voyager-galaxy-stars';
import { VoyagerGalaxy } from './components/voyager-galaxy';
import { VoyagerOriginStars } from './components/voyager-origin-stars';
import { VoyagerOrigin } from './components/voyager-origin';
import { VoyagerProductStarsFilled } from './components/voyager-product-stars-filled';
import { VoyagerProductStars } from './components/voyager-product-stars';
import { VoyagerProduct } from './components/voyager-product';
import { VoyagerUniverseStars } from './components/voyager-universe-stars';
import { VoyagerUniverse } from './components/voyager-universe';

    import { createIconComponent } from './utils';

    export type { IconComponent, IconProps } from './utils';

    // Feather icons
    export const FeatherActivityIcon = createIconComponent(FeatherActivity)
export const FeatherAlertOctagonIcon = createIconComponent(FeatherAlertOctagon)
export const FeatherAlertTriangleIcon = createIconComponent(FeatherAlertTriangle)
export const FeatherArrowDownIcon = createIconComponent(FeatherArrowDown)
export const FeatherArrowLeftIcon = createIconComponent(FeatherArrowLeft)
export const FeatherArrowRightIcon = createIconComponent(FeatherArrowRight)
export const FeatherArrowUpIcon = createIconComponent(FeatherArrowUp)
export const FeatherArrowUpRightIcon = createIconComponent(FeatherArrowUpRight)
export const FeatherAwardIcon = createIconComponent(FeatherAward)
export const FeatherCalendarIcon = createIconComponent(FeatherCalendar)
export const FeatherCameraIcon = createIconComponent(FeatherCamera)
export const FeatherCheckIcon = createIconComponent(FeatherCheck)
export const FeatherCheckCircleIcon = createIconComponent(FeatherCheckCircle)
export const FeatherChevronDownIcon = createIconComponent(FeatherChevronDown)
export const FeatherChevronLeftIcon = createIconComponent(FeatherChevronLeft)
export const FeatherChevronRightIcon = createIconComponent(FeatherChevronRight)
export const FeatherChevronUpIcon = createIconComponent(FeatherChevronUp)
export const FeatherCircleIcon = createIconComponent(FeatherCircle)
export const FeatherClipboardIcon = createIconComponent(FeatherClipboard)
export const FeatherClockIcon = createIconComponent(FeatherClock)
export const FeatherCopyIcon = createIconComponent(FeatherCopy)
export const FeatherDollarSignIcon = createIconComponent(FeatherDollarSign)
export const FeatherDownloadIcon = createIconComponent(FeatherDownload)
export const FeatherEdit2Icon = createIconComponent(FeatherEdit2)
export const FeatherEyeIcon = createIconComponent(FeatherEye)
export const FeatherEyeOffIcon = createIconComponent(FeatherEyeOff)
export const FeatherFileTextIcon = createIconComponent(FeatherFileText)
export const FeatherFilterIcon = createIconComponent(FeatherFilter)
export const FeatherGiftIcon = createIconComponent(FeatherGift)
export const FeatherHelpCircleIcon = createIconComponent(FeatherHelpCircle)
export const FeatherHomeIcon = createIconComponent(FeatherHome)
export const FeatherInfoIcon = createIconComponent(FeatherInfo)
export const FeatherLayersIcon = createIconComponent(FeatherLayers)
export const FeatherListIcon = createIconComponent(FeatherList)
export const FeatherLockIcon = createIconComponent(FeatherLock)
export const FeatherMailIcon = createIconComponent(FeatherMail)
export const FeatherMenuIcon = createIconComponent(FeatherMenu)
export const FeatherMessageSquareIcon = createIconComponent(FeatherMessageSquare)
export const FeatherMinusIcon = createIconComponent(FeatherMinus)
export const FeatherMoonIcon = createIconComponent(FeatherMoon)
export const FeatherMoreVerticalIcon = createIconComponent(FeatherMoreVertical)
export const FeatherPlusIcon = createIconComponent(FeatherPlus)
export const FeatherPlusCircleIcon = createIconComponent(FeatherPlusCircle)
export const FeatherRefreshCwIcon = createIconComponent(FeatherRefreshCw)
export const FeatherSaveIcon = createIconComponent(FeatherSave)
export const FeatherSearchIcon = createIconComponent(FeatherSearch)
export const FeatherSettingsIcon = createIconComponent(FeatherSettings)
export const FeatherShareIcon = createIconComponent(FeatherShare)
export const FeatherSlashIcon = createIconComponent(FeatherSlash)
export const FeatherSunIcon = createIconComponent(FeatherSun)
export const FeatherFrownIcon = createIconComponent(FeatherFrown)
export const FeatherTrash2Icon = createIconComponent(FeatherTrash2)
export const FeatherUploadIcon = createIconComponent(FeatherUpload)
export const FeatherUserIcon = createIconComponent(FeatherUser)
export const FeatherXIcon = createIconComponent(FeatherX)
export const FeatherZapIcon = createIconComponent(FeatherZap)
export const FeatherZapOffIcon = createIconComponent(FeatherZapOff)

    // Streamline icons
    export const StreamlineAccountingCoinsStackIcon = createIconComponent(StreamlineAccountingCoinsStack)
export const StreamlineAccountingCoinsIcon = createIconComponent(StreamlineAccountingCoins)
export const StreamlineAlertTriangleIcon = createIconComponent(StreamlineAlertTriangle)
export const StreamlineAmericanFootballBall1Icon = createIconComponent(StreamlineAmericanFootballBall1)
export const StreamlineAnalyticsPie1Icon = createIconComponent(StreamlineAnalyticsPie1)
export const StreamlineAstronomyTelescopeStarsIcon = createIconComponent(StreamlineAstronomyTelescopeStars)
export const StreamlineBuildingModern2Icon = createIconComponent(StreamlineBuildingModern2)
export const StreamlineBusinessContractApproveIcon = createIconComponent(StreamlineBusinessContractApprove)
export const StreamlineButtonLoopArrowIcon = createIconComponent(StreamlineButtonLoopArrow)
export const StreamlineCashBriefcaseIcon = createIconComponent(StreamlineCashBriefcase)
export const StreamlineCashBulbIcon = createIconComponent(StreamlineCashBulb)
export const StreamlineCashPaymentBillsIcon = createIconComponent(StreamlineCashPaymentBills)
export const StreamlineCashPaymentWalletIcon = createIconComponent(StreamlineCashPaymentWallet)
export const StreamlineCashSearchIcon = createIconComponent(StreamlineCashSearch)
export const StreamlineCheck1Icon = createIconComponent(StreamlineCheck1)
export const StreamlineCheckCircle1Icon = createIconComponent(StreamlineCheckCircle1)
export const StreamlineCoffeeToGoIcon = createIconComponent(StreamlineCoffeeToGo)
export const StreamlineColorPaletteIcon = createIconComponent(StreamlineColorPalette)
export const StreamlineCommonFileRotateIcon = createIconComponent(StreamlineCommonFileRotate)
export const StreamlineContentPaperEditIcon = createIconComponent(StreamlineContentPaperEdit)
export const StreamlineContentPencilWriteIcon = createIconComponent(StreamlineContentPencilWrite)
export const StreamlineConversationChatBubbleIcon = createIconComponent(StreamlineConversationChatBubble)
export const StreamlineCursorChooseIcon = createIconComponent(StreamlineCursorChoose)
export const StreamlineDiagramArrowDashRightIcon = createIconComponent(StreamlineDiagramArrowDashRight)
export const StreamlineDiscountCircleIcon = createIconComponent(StreamlineDiscountCircle)
export const StreamlineDownloadDashArrowIcon = createIconComponent(StreamlineDownloadDashArrow)
export const StreamlineEarthCashIcon = createIconComponent(StreamlineEarthCash)
export const StreamlineFaceId4Icon = createIconComponent(StreamlineFaceId4)
export const StreamlineFastFoodBurgerDrinkIcon = createIconComponent(StreamlineFastFoodBurgerDrink)
export const StreamlineFlagPlain3Icon = createIconComponent(StreamlineFlagPlain3)
export const StreamlineGenderHeteroIcon = createIconComponent(StreamlineGenderHetero)
export const StreamlineGraphStatsAscendIcon = createIconComponent(StreamlineGraphStatsAscend)
export const StreamlineHourglassIcon = createIconComponent(StreamlineHourglass)
export const StreamlineKoalaIcon = createIconComponent(StreamlineKoala)
export const StreamlineLegalHammerIcon = createIconComponent(StreamlineLegalHammer)
export const StreamlineLegalScale1Icon = createIconComponent(StreamlineLegalScale1)
export const StreamlineLegalScaleUnequal1Icon = createIconComponent(StreamlineLegalScaleUnequal1)
export const StreamlineLightBulbIcon = createIconComponent(StreamlineLightBulb)
export const StreamlineLightModeSunnyIcon = createIconComponent(StreamlineLightModeSunny)
export const StreamlineLogout1Icon = createIconComponent(StreamlineLogout1)
export const StreamlineMakeUpEyeIcon = createIconComponent(StreamlineMakeUpEye)
export const StreamlineMonitorHeartBeatIcon = createIconComponent(StreamlineMonitorHeartBeat)
export const StreamlineMultipleNeutral2Icon = createIconComponent(StreamlineMultipleNeutral2)
export const StreamlineMultipleUsers1Icon = createIconComponent(StreamlineMultipleUsers1)
export const StreamlineNaturalDisasterFireIcon = createIconComponent(StreamlineNaturalDisasterFire)
export const StreamlineNetworkPinIcon = createIconComponent(StreamlineNetworkPin)
export const StreamlineNetworkUserIcon = createIconComponent(StreamlineNetworkUser)
export const StreamlineOfficialBuilding2Icon = createIconComponent(StreamlineOfficialBuilding2)
export const StreamlinePastaBowlWarmIcon = createIconComponent(StreamlinePastaBowlWarm)
export const StreamlineReligionPeace1Icon = createIconComponent(StreamlineReligionPeace1)
export const StreamlineSatellite1Icon = createIconComponent(StreamlineSatellite1)
export const StreamlineSavingBank1Icon = createIconComponent(StreamlineSavingBank1)
export const StreamlineSearch1Icon = createIconComponent(StreamlineSearch1)
export const StreamlineShieldCheckIcon = createIconComponent(StreamlineShieldCheck)
export const StreamlineShieldWarningIcon = createIconComponent(StreamlineShieldWarning)
export const StreamlineSingleManBookIcon = createIconComponent(StreamlineSingleManBook)
export const StreamlineSingleNeutralShieldIcon = createIconComponent(StreamlineSingleNeutralShield)
export const StreamlineSingleNeutralIcon = createIconComponent(StreamlineSingleNeutral)
export const StreamlineSmileySmile2Icon = createIconComponent(StreamlineSmileySmile2)
export const StreamlineSpaceAstronautAlternateIcon = createIconComponent(StreamlineSpaceAstronautAlternate)
export const StreamlineSpaceRocketFlyingIcon = createIconComponent(StreamlineSpaceRocketFlying)
export const StreamlineStudyDeskIcon = createIconComponent(StreamlineStudyDesk)
export const StreamlineTargetCenter1Icon = createIconComponent(StreamlineTargetCenter1)
export const StreamlineTaskListMultipleIcon = createIconComponent(StreamlineTaskListMultiple)
export const StreamlineTaxiWaveBusinessManIcon = createIconComponent(StreamlineTaxiWaveBusinessMan)
export const StreamlineTimeClockCircleIcon = createIconComponent(StreamlineTimeClockCircle)
export const StreamlineTouchId2Icon = createIconComponent(StreamlineTouchId2)
export const StreamlineToysLegoIcon = createIconComponent(StreamlineToysLego)
export const StreamlineTreeIcon = createIconComponent(StreamlineTree)
export const StreamlineWalkingIcon = createIconComponent(StreamlineWalking)
export const StreamlineWaterProtectionFishIcon = createIconComponent(StreamlineWaterProtectionFish)
export const StreamlineWaterStrawIcon = createIconComponent(StreamlineWaterStraw)
export const StreamlineWeatherCloudRainIcon = createIconComponent(StreamlineWeatherCloudRain)

    // Custom Line icons
    export const AnalyticsLineGraphIcon = createIconComponent(AnalyticsLineGraph)
export const AstronomyPlanetIcon = createIconComponent(AstronomyPlanet)
export const AwardIcon = createIconComponent(Award)
export const BoostsIcon = createIconComponent(Boosts)
export const BriefcaseIcon = createIconComponent(Briefcase)
export const CloudEmptyIcon = createIconComponent(CloudEmpty)
export const DatabaseSearchIcon = createIconComponent(DatabaseSearch)
export const DollarBillsIcon = createIconComponent(DollarBills)
export const DollarCircleIcon = createIconComponent(DollarCircle)
export const DollarCirleLinesIcon = createIconComponent(DollarCirleLines)
export const FundFromSpaceshipIcon = createIconComponent(FundFromSpaceship)
export const FundToSpaceshipIcon = createIconComponent(FundToSpaceship)
export const GalaxyIcon = createIconComponent(Galaxy)
export const GaugeDashboardMaxIcon = createIconComponent(GaugeDashboardMax)
export const HelpWheelIcon = createIconComponent(HelpWheel)
export const KeyboardArrowLeftIcon = createIconComponent(KeyboardArrowLeft)
export const MergeArrowIcon = createIconComponent(MergeArrow)
export const MoneyPlantIcon = createIconComponent(MoneyPlant)
export const SmileyBlessedIcon = createIconComponent(SmileyBlessed)
export const TipLightbulbIcon = createIconComponent(TipLightbulb)

    // Custom Fill icons
    export const ChecklistIcon = createIconComponent(Checklist, true)
export const CircleCheckIcon = createIconComponent(CircleCheck, true)
export const CircleIcon = createIconComponent(Circle, true)
export const EtfAssetFallbackIcon = createIconComponent(EtfAssetFallback, true)
export const EtfProductStarsIcon = createIconComponent(EtfProductStars, true)
export const EtfProductIcon = createIconComponent(EtfProduct, true)
export const FacebookIcon = createIconComponent(Facebook, true)
export const IndicatorTriangleIcon = createIconComponent(IndicatorTriangle, true)
export const InstagramIcon = createIconComponent(Instagram, true)
export const LinkedinIcon = createIconComponent(Linkedin, true)
export const PadlockAttentionIcon = createIconComponent(PadlockAttention, true)
export const PayidIcon = createIconComponent(Payid, true)
export const RssIcon = createIconComponent(Rss, true)
export const SpaceshipIcon = createIconComponent(Spaceship, true)
export const StocksProductStarsIcon = createIconComponent(StocksProductStars, true)
export const StocksProductIcon = createIconComponent(StocksProduct, true)
export const SuperGlobalIndexStarsIcon = createIconComponent(SuperGlobalIndexStars, true)
export const SuperGlobalIndexIcon = createIconComponent(SuperGlobalIndex, true)
export const SuperGrowthxStarsIcon = createIconComponent(SuperGrowthxStars, true)
export const SuperGrowthxIcon = createIconComponent(SuperGrowthx, true)
export const SuperProductStarsIcon = createIconComponent(SuperProductStars, true)
export const SuperProductIcon = createIconComponent(SuperProduct, true)
export const TaxTimeIcon = createIconComponent(TaxTime, true)
export const TimelineIcon = createIconComponent(Timeline, true)
export const TwitterIcon = createIconComponent(Twitter, true)
export const VoyagerEarthStarsIcon = createIconComponent(VoyagerEarthStars, true)
export const VoyagerEarthIcon = createIconComponent(VoyagerEarth, true)
export const VoyagerExplorerStarsIcon = createIconComponent(VoyagerExplorerStars, true)
export const VoyagerExplorerIcon = createIconComponent(VoyagerExplorer, true)
export const VoyagerGalaxyStarsIcon = createIconComponent(VoyagerGalaxyStars, true)
export const VoyagerGalaxyIcon = createIconComponent(VoyagerGalaxy, true)
export const VoyagerOriginStarsIcon = createIconComponent(VoyagerOriginStars, true)
export const VoyagerOriginIcon = createIconComponent(VoyagerOrigin, true)
export const VoyagerProductStarsFilledIcon = createIconComponent(VoyagerProductStarsFilled, true)
export const VoyagerProductStarsIcon = createIconComponent(VoyagerProductStars, true)
export const VoyagerProductIcon = createIconComponent(VoyagerProduct, true)
export const VoyagerUniverseStarsIcon = createIconComponent(VoyagerUniverseStars, true)
export const VoyagerUniverseIcon = createIconComponent(VoyagerUniverse, true)
  